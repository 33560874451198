import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

	constructor() { }

	result(status, message) {
		return { 
			status:status,
			message:message
		};
	}
	
	checkLogin(login) {

		if(!login['email'].valid) {
			return this.result(false, 'Please enter valid email id');
		}

		if(!login['password'].valid) {
			return this.result(false, 'Please enter password');
		}

		return this.result(true, 'Success');
	}

	checkResetForm(resetForm) {
		
		if(!resetForm['temporaryPassword'].valid) {
			return this.result(false, 'Please enter temporary password');
		}

		if(!resetForm['newPwd'].valid) {
			if(resetForm['newPwd'].value.length === 0 ) {
				return this.result(false, 'Please enter the password');
			}
			if(resetForm['newPwd'].value.length < 6) {
				return this.result(false, 'Password Length should be greater than 7');
			}
			
		}

		if(!resetForm['confirmPassword'].valid) {
			if(resetForm['confirmPassword'].value.length === 0 ) {
				return this.result(false, 'Please enter the confirm password');
			}
		}

		if(resetForm['confirmPassword'].value !== resetForm['newPwd'].value) {
			return this.result(false, 'Passwords don\'t match');
		}

		return this.result(true, 'Success');
	}

	checkSetForm(setForm) {
		
		if(!setForm['temporaryPassword'].valid) {
			return this.result(false, 'Please enter temporary password');
		}

		if(!setForm['newPwd'].valid) {
			if(setForm['newPwd'].value.length === 0 ) {
				return this.result(false, 'Please enter the password');
			}
			if(setForm['newPwd'].value.length < 6) {
				return this.result(false, 'Password Length should be greater than 7');
			}
			
		}

		if(!setForm['confirmPassword'].valid) {
			if(setForm['confirmPassword'].value.length === 0 ) {
				return this.result(false, 'Please enter the confirm password');
			}
		}

		if(setForm['confirmPassword'].value !== setForm['newPwd'].value) {
			return this.result(false, 'Passwords don\'t match');
		}

		return this.result(true, 'Success');
	}

	checkUserForm(userForm) {

		if(!userForm['role'].valid) {
			return this.result(false, 'Please select role');
		}

		if(userForm['role'].value == "Please select") {
			return this.result(false, 'Please select role');
		}

		if(!userForm['firstName'].valid) {
			return this.result(false, 'Please enter first name');
		}

		if(!userForm['lastName'].valid) {
			return this.result(false, 'Please enter last name');
		}

		if(!userForm['email'].valid) {
			return this.result(false, 'Please enter valid email id');
		}

		if(!userForm['contactNumber'].valid) {
			return this.result(false, 'Please enter contact number');
		}
		
		if(!userForm['isAccountManager'].value) {
			if(!userForm['clientId'].valid) {
				return this.result(false, 'Please select client name');
			}
	
			if(userForm['clientId'].value == "Please select") {
				return this.result(false, 'Please select client name');
			}
		}
		
		return this.result(true, 'Success');
	}
}
